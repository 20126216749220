import React from "react";
import "../styles/ActionSection.css";

const ActionSection = () => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
      };
    return (
        <div className="action-section">
            <div className="action-content">
                <div className="action-text">
                    <div>
                        <h4 className="action-subtitle">Empowering the Digital Future</h4>
                        <h1 className="action-title">
                            Cutting-Edge Networking Solutions for Global Connectivity and Innovation
                        </h1>
                    </div>

                    <a href="#contact" onClick={() => scrollToSection('contact')} className="donate-button">Join Now ❤</a>
                </div>
            </div>
            <div className="stats-section">
                <div className="stat-item">
                    <h2 className="stat-value">500Gbps</h2>
                    <p className="stat-label">Network Bandwidth Capacity</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">200K+</h2>
                    <p className="stat-label">Connected Devices Globally</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">99.99%</h2>
                    <p className="stat-label">Network Uptime</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">1PB+</h2>
                    <p className="stat-label">Data Transferred Monthly</p>
                </div>
            </div>

        </div>
    );
};

export default ActionSection;
