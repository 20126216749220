import React from 'react';
import '../styles/OurCampaign.css'; // Import the CSS for styling
// Dummy images (replace these with your actual image imports)
import campaignImage1 from '../assets/networking4.jpg';
import campaignImage2 from '../assets/networking2.jpg';
import campaignImage3 from '../assets/networking03.jpg';

const OurCampaign = () => {
  const campaigns = [
    {
      image: campaignImage3,
      title: 'Individuals looking for a knowledge base and techniques, templates and advice to ensure you maximise your return on time (Early supporting members will be highly valued and rewarded)',
    },
    {
      image: campaignImage2,
      title: 'Designed to promote small operators, SME’s, Facilitators and those tasked with managing networking experiences',
    },
    {
      image: campaignImage1,
      title: 'Providing the businesses that provide software, space and services that build the provisions required for highly successful events',
    },
  ];

  return (
    <div id='our-campaign' className="our-campaign-container">
      <div className='main-titles'>
      <h3 className="section-subtitle">Benefits</h3>
      <h1 className="section-title">Networking will be an essential skill and requirement in the near future when job insecurity due to AI and Automation is rife.</h1>
      </div>
      <div className="campaign-cards-tab">
        {campaigns.map((campaign, index) => (
          <div key={index} className="campaign-card-tab">
            <img src={campaign.image} alt={campaign.title} className="campaign-image" />
            <h5 className="campaign-title">{campaign.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurCampaign;
