import React from 'react';
import '../styles/LandingPage.css';
// import ActivitiesIcon from '../assets/activities-icon.svg';
import logo from '../assets/logo.png';
// import GPayIcon from '../assets/g-pay.svg';
// import ApplePayIcon from '../assets/apple-pay.svg';
// import HeartIcon from '../assets/heart-icon.svg';
import useMobileView from '../hooks/useMobileView';


const LandingPage = () => {
  const isMobileView = useMobileView();
  return (
    <div id='landing-page'>
      {isMobileView ? (

        <div className='landing-page-main'>
          <header className="header2">
            <div className="header-bottom">
              <a href="/" className="logo">
                <img src={logo} alt="Donaty Icon" width="30" height="30" />
                <p className='logo-text'>Pan Global</p>
              </a>
              {/* <button className="donate-button">
                <span className='button-text'>Join Now</span>
                <img src={HeartIcon} alt="Heart Icon" width="12" height="10" />
              </button> */}
            </div>
          </header>

          <div className="landing-page">
            <div className='primary-section'>

              <section className="main-section">
                {/* <strong>TRUSTED CHARITY COMPANY</strong> */}
                <h1>
                  {/* Let’s Help And Make People Smile By <span>Giving</span> Of Yours */}
                  {/* We are building an association to prove the importance of professional networking. */}
                  Join us in ensuring the future of work is bright, open to all and in advocating the benefits of professional networking to stakeholders.
                </h1>
                <div className='main-buttons'>
                  <button className="get-started-button">Get Started</button>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (

        <div className='landing-page-main'>
          <header className="header2">
            <div className="header-top2">
            </div>
            <div className="header-bottom">
              <a href="/" className="logo">
                <img src={logo} alt="Donaty Icon" width="50" height="50" />
                <p className='logo-text'>Pan Global</p> </a>
              {/* <div className="header-bottom2">

                <button className="donate-button"><span className='button-text'>Join Now</span> <img src={HeartIcon} alt="Heart Icon" width="15" height="12" /></button>
              </div> */}
            </div>
          </header>

          <div className="landing-page">
            <div className='primary-section'>

              <section className="main-section">
                {/* <strong>TRUSTED CHARITY COMPANY</strong> */}
                <h1>
                  {/* Let’s Help And Make People Smile By <span>Giving</span> Of Yours */}
                  {/* We are building an association to prove the importance of professional networking */}
                  Join us in ensuring the future of work is bright, open to all and in advocating the benefits of professional networking to stakeholders.
                </h1>
            
                <div className='main-buttons'>
                  <button className="get-started-button">Get Started</button>

                  {/* <button className="activity-button"><img src={ActivitiesIcon} alt="Heart Icon" width="70" /></button> */}
                </div>
              </section>

              {/* <div className="campaign-section-main">
              <section className="campaign-section">
                <div className="design-border"></div>
                <div className="campaign-card">
                  <div className='donate-heart'><img src={DonateIcon} alt="Heart Icon" width="90" height="90" /></div>
                  <div className="campaign-tag">Education</div>
                  <h2>Help Children Get out of Poverty & have a Future</h2>
                  <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.
                  </p>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: '29%' }}></div>
                  </div>
                  <div className="campaign-stats">
                    <span>$105,480 Raised</span>
                    <span>$20,000 Goal</span>
                  </div>
                  <div className="payment-buttons">
                    <button className="apple-pay-button"><img src={ApplePayIcon} alt="Phone Icon" width="140" /></button>
                    <button className="google-pay-button"><img src={GPayIcon} alt="Phone Icon" width="140" /></button>
                  </div>
                </div>
              </section>
            </div> */}
            </div>
          </div>
        </div>


      )}
    </div>

  );
};

export default LandingPage;
