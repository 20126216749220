import React from 'react';
import LandingPage from './components/LandingPage';
import WhoWeAre from './components/WhoWeAre';
import OurCampaign from './components/OurCampaign';
import LatestNews from './components/LatestNews';
// import Testimonials from './components/Testimonials';
import ActionSection from './components/ActionSection';
import NeedHelp from './components/NeedHelp';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactUs from './components/ContactUs';
import Team from './components/Team';
function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <LandingPage />
        <LatestNews />
        <WhoWeAre />
        <OurCampaign />
        {/* <Testimonials /> */}
        <ActionSection />
        <NeedHelp />
        <Team/>
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
}

export default App;
