import React from 'react';
import '../styles/LandingPage.css';

// import DonatyIcon from '../assets/donaty-logo.svg';
import MailIcon from '../assets/mail-icon.svg';
// import PhoneIcon from '../assets/phone-call.png';
// import HeartIcon from '../assets/heart-icon.svg';
// import TwitterIcon from '../assets/twitter-icon.svg';
// import InstagramIcon from '../assets/instagram-icon.svg';
// import FacebookIcon from '../assets/facebook-icon.svg';
// import YoutubeIcon from '../assets/youtube-icon.svg';
// import DropDownIcon from '../assets/drop-icon.svg';
import useMobileView from '../hooks/useMobileView';
// import { NavLink } from 'react-router-dom';
// import { Link } from 'react-router-dom';
const Header = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };
  const isMobileView = useMobileView();
  return (
    <div className='header-div'>
      <header className="header">
        <div className="header-top">
          <div className="header-contact">
            <div>
              {/* <span><img src={PhoneIcon} alt="Phone Icon" /> +1-206-156 2849</span> */}
              <span><img src={MailIcon} alt="Mail Icon" /> adam@panglobal.network</span>
            </div>

            <nav className="main-nav">
              {/* <a href="#landing-page" onClick={() => scrollToSection('landing-page')}
                className={({ isActive }) =>
                  isActive ? "nav-link active-link" : "nav-link"
                }>Home</a> */}
              <a href="#who-we-are" onClick={() => scrollToSection('who-we-are')} >Who We Are</a>
              <a href="#our-campaign" onClick={() => scrollToSection('our-campaign')}>Benefits</a>
              <a href="#need-help" onClick={() => scrollToSection('need-help')}>Need Help</a>
              <a href="#team-tab" onClick={() => scrollToSection('team-tab')}>Team</a>
              <a href="#contact" onClick={() => scrollToSection('contact')}>Contact Us</a>
            </nav>
          </div>
          {/* <nav className="nav">
            <a href="#"><img src={TwitterIcon} alt="Twitter Icon" /></a>
            <a href="#"><img src={InstagramIcon} alt="Instagram Icon" /></a>
            <a href="#"><img src={FacebookIcon} alt="Facebook Icon" /></a>
            <a href="#"><img src={YoutubeIcon} alt="Youtube Icon" /></a>
          </nav> */}
        </div>
      </header>
      {/* main header content */}
      {isMobileView ? (
        <header className="header24">
          {/* <div className="header-bottom">
            <a href="/" className="logo">
              <p className='logo-text'>Pan Global</p>
            </a>
            <button className="donate-button">
              <span className='button-text'>Join Now</span>
              <img src={HeartIcon} alt="Heart Icon" width="12" height="10" />
            </button>
          </div> */}
        </header>
      ) : (
        <header >
          {/* <div className="header-top2">
          </div>
          <div className="header-bottom">
            <a href="/" className="logo">

              <p className='logo-text'>Pan Global</p> </a>
            <div className="header-bottom2">

              <button className="donate-button"><span className='button-text'>Join Now</span> <img src={HeartIcon} alt="Heart Icon" width="15" height="12" /></button>
            </div>
          </div> */}
        </header>
      )}
    </div>
  );
};

export default Header;
