import React, { useState } from "react";
import "../styles/NeedHelp.css"; // Create corresponding CSS for styling

const NeedHelp = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div id="need-help" className="need-help-container">
        <h3 className="need-help-title">Need Help?</h3>
        <h1 className="faq-title">Networking Solutions and Guidance</h1>
        <p className="faq-description">
          We provide insights, tools, and strategies to empower you in building strong, meaningful connections in the digital world.
        </p>
        <div className="accordion">
          <div className="accordion-item">
            <div
              className={`accordion-header ${
                activeIndex === 0 ? "active" : ""
              }`}
              onClick={() => toggleAccordion(0)}
            >
              How can networking help my career growth?
            </div>
            <div
              className={`accordion-content ${
                activeIndex === 0 ? "active" : ""
              }`}
            >
              Networking will be an essential skill in the near future as automation and AI reshape job security. Building professional relationships will help you stay relevant and open up new opportunities in your career.
            </div>
          </div>
  
          <div className="accordion-item">
            <div
              className={`accordion-header ${
                activeIndex === 1 ? "active" : ""
              }`}
              onClick={() => toggleAccordion(1)}
            >
              What strategies can I use to make lasting connections?
            </div>
            <div
              className={`accordion-content ${
                activeIndex === 1 ? "active" : ""
              }`}
            >
              We provide proven methods, templates, and models to help you establish durable, lasting relationships. Focus on human-centric approaches to foster meaningful connections.
            </div>
          </div>
  
          <div className="accordion-item">
            <div
              className={`accordion-header ${
                activeIndex === 2 ? "active" : ""
              }`}
              onClick={() => toggleAccordion(2)}
            >
              How can I leverage networking events effectively?
            </div>
            <div
              className={`accordion-content ${
                activeIndex === 2 ? "active" : ""
              }`}
            >
              We offer clear frameworks to help you maximize benefits from networking events, including making the most of your interactions with attendees, facilitators, service providers, and sponsors.
            </div>
          </div>
  
          <div className="accordion-item">
            <div
              className={`accordion-header ${
                activeIndex === 3 ? "active" : ""
              }`}
              onClick={() => toggleAccordion(3)}
            >
              How can I contribute to building a supportive networking community?
            </div>
            <div
              className={`accordion-content ${
                activeIndex === 3 ? "active" : ""
              }`}
            >
              We promote inclusivity, ensuring that all participants feel comfortable and benefit from best practices. Whether you're a seasoned professional or just starting out, we provide guidance to help you build a strong network.
            </div>
          </div>
  
          <div className="accordion-item">
            <div
              className={`accordion-header ${
                activeIndex === 4 ? "active" : ""
              }`}
              onClick={() => toggleAccordion(4)}
            >
              Why is networking critical in a rapidly changing job market?
            </div>
            <div
              className={`accordion-content ${
                activeIndex === 4 ? "active" : ""
              }`}
            >
              With the rise of AI and automation, job security is becoming uncertain. Developing networking skills now will ensure you're prepared for future challenges and can create lasting professional relationships that benefit your career.
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default NeedHelp;
