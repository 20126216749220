import React from 'react';
import '../styles/WhoWeAre.css'; // Importing the CSS file for styling
// import DonateChildrens from '../assets/donate-childrens.svg';
// import DonateChildrens2 from '../assets/donate-childrens2.svg';
// import DonateChildLogo from '../assets/donate-child-logo.svg';
const WhoWeAre = () => {
    return (
        <div id="who-we-are" className="who-we-are-container">
            <div className="image-section">
                <div className="main-image">
                    <img src='https://static.vecteezy.com/system/resources/thumbnails/002/910/682/small_2x/outsourcing-company-concept-in-flat-design-vector.jpg' alt='imgs' />
                </div>
                {/* <div className="overlay-image">
                    <img src='https://codingjrgfs.objectstore.e2enetworks.net/uploads/thumbnails/course_thumbnails/course_thumbnail_default_4284.jpg' />
                </div> */}
                {/* <div className="icon">
                    <img src={DonateChildLogo} alt="Mother and child" />
                </div> */}
            </div>
            <div className="text-section">
                <strong className="subtitle">Who we are</strong>
                <h1 className="title"> We are building an association to prove the importance of professional networking</h1>
                <p className="description">
                Join us in ensuring the future of work and ensure the benefits of professional networking are open to all.
                </p>
                <ul className="benefits-list">
                    <li>
                        <span className="check-icon">&#x2714;</span> Support Professionals in Need.
                    </li>
                    <li>
                        <span className="check-icon">&#x2714;</span> Largest Global Networking Community.
                    </li>
                    <li>
                        <span className="check-icon">&#x2714;</span> Enhance Professional Connections.
                    </li>
                    <li>
                        <span className="check-icon">&#x2714;</span> Share Your Network for Success.
                    </li>
                </ul>
                {/* <button className="about-us-button">About Us</button> */}
            </div>
        </div>
    );
};

export default WhoWeAre;
